/* Australia Page Css start */

/* New Contact us Section */

.custom-scroll-right {
  overflow: hidden !important;
}

.custom-scroll-right-bottum {
  overflow: hidden !important;
  border-bottom: 3px solid #f26524;
}

.custom-scroll-right:hover {
  overflow-y: scroll !important;
}

.custom-scroll-right-bottum:hover {
  overflow: scroll !important;
  border-bottom: none;
}

.Contact_form form > div input,
.Contact_form form > div textarea {
  border: 0.1px solid rgb(192, 192, 192);
  padding: 5px !important;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.Close_btn {
  position: absolute;
  top: -10px;
  right: 1%;
  font-size: 14px;
  cursor: pointer;
}
/* .Student_Story {
    height: 280px;
  } */

.Close_btn span {
  font-size: 22px;
  padding: 0;
  margin: 0;
  color: #f36525;
}

.sidebar-form {
  position: fixed;
  right: -250px;
  width: 250px;
  background-color: white;
  top: 75%;
  padding: 0 15px 10px 15px;
  padding-top: 15px;
  transform: translateY(-100%);
  transition: right 0.6s linear;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sidebar-form.show {
  right: 0;
}

.sidebar-form .call-action {
  position: absolute;
  background-color: #f36525;
  font-weight: 400;
  color: white;
  height: 40px;
  padding: 14px;
  text-align: center;
  letter-spacing: 1px;
  left: -120px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%) rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-transform: uppercase;
}

.Contact_Form_heading {
  /* font-family: "montserrat"; */
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px !important;

  color: rgb(57, 51, 85);
  text-decoration: underline;
  text-decoration-color: rgb(57, 51, 85);
  text-underline-offset: 0.5rem;
  text-decoration-thickness: 2px;
}
.Contact_Form_heading p {
  font-size: 15px;
}

.sidebar-form .form-group {
  margin-bottom: 10px;
}

.sidebar-form .form-group textarea {
  min-height: 70px;
}

.sidebar-form .form-group label {
  font-weight: 500px;
  font-size: 15px;
  margin-bottom: 2px;
}
.sidebar-form #submit-btn {
  width: 100%;
  border: none;
  background-color: #f36525;
  margin-top: 8px;
  padding: 7px;
  font-weight: 400;
  cursor: pointer;
  color: white;
  border-radius: 5px;
}

.Book_Free .sidebar-form .call-action {
  position: absolute;
  background-color: #f36525;
  font-weight: 400;
  color: white;
  height: 40px;
  padding: 14px;
  text-align: center;
  letter-spacing: 1px;
  left: -142px !important;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%) rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-transform: uppercase;
}

.Australia_page_what_we .slick-slide {
  padding: 10px;
}

#Counsellor_Page {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 98% !important;
  height: 300px;
  border-radius: 10px;
}
.Featured_Courses {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 50px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Featured_Courses .College_logo {
  /* width: 230px;
   
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    border-radius: 5px; */

  border: 1.136px solid #e5e5e5;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  height: 100px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
}

.Featured_Courses_Container {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 80vh;
}

.Our_Membership {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding-bottom: 20px;
}

.Our_Member {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 0.1px solid grey;
  color: white;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Our_Member img {
  width: 100%;
}

/* Scroll bar css */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  margin-top: 5px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
  background: #b0b5b9;
  border-radius: 10px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb:hover {
  background: #f26524;
  border-radius: 10px;
  scroll-behavior: smooth;
}

/* scroll bar css end */

@media screen and (min-width: 200px) and (max-width: 780px) {
  .sidebar-form {
    right: -240px;
    width: 240px;
    background-color: white;
    top: 90%;
  }

  .Featured_Courses_Container {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 50vh;
    overflow-y: auto;
  }
}

.TextHide {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  background: #fff;
}
@media screen and (min-width: 200px) and (max-width: 480px) {
  .sidebar-form {
    right: -220px;
    width: 220px;
    background-color: white;
    top: 93%;
  }

  .Featured_Courses {
    display: flex;
    flex-direction: column;
  }
  .Our_Membership {
    flex-direction: column;
  }

  .Our_Member {
    width: 100px;
    height: 200px;
  }
}

/*-------------Note-----body-----overlay-------------*/

.overlay-note {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-note-mini-max {
  /*height: 100%;*/
  width: 36%;
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  /*  background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.6);*/
  overflow-x: hidden;
  display: none;
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.9s;
  animation-name: fadeIn;
  animation-duration: 0.9s;
}

/* .overlay-note-mini-max {
          animation: overlay-note-mini-max 1s ease;
          -webkit-animation: overlay-note-mini-max 1s ease;
      }
      @keyframes overlay-note-mini-max {
          from{transform:scale(0.9)}
          100%{transform:scale(1.2)}
          to{transform:scale(1)}
      }
      @-webkit-keyframes overlay-note-mini-max {
          from{-webkit-transform:scale(0.9)}
          100%{-webkit-transform:scale(1.2)}
          to{-webkit-transform:scale(1)}
      }*/

.overlay-note-content {
  position: relative;
  top: 15%;
  width: 100%;
  margin-top: 30px;
}

.overlay-note a:hover,
.overlay-note a:focus {
  color: #f1f1f1;
}

.overlay-note .closebtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 20px;
}

.overlay-note .closebtn i {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
}

.overlay-note-mini-max .closebtn {
  position: absolute;
  top: 1px;
  right: 10px;
  font-size: 20px;
}
.overlay-note-mini-max .closebtn i {
  color: #fff;
  font-size: 13px;
  line-height: 13px;
}

.overlay-note-mini-max .overlay-note-content {
  width: 84%;
  float: right;
  margin-right: 15%;
  background-color: #f4f4f4;
  color: #777;
  border-radius: 5px;
}
.w-100 {
  width: 100%;
}
.overlay-note-mini-max .overlay-note-content {
  position: relative;
  top: 0px;
  margin-top: 0px;
  box-shadow: 0px 3px 6px #0000005c;
}

.overlay-note-mini-max .note-title {
  border-radius: 5px 5px 0px 0px;
}

.overlay-note-mini-max .note-title h4 {
  font-size: 18px;
  letter-spacing: 0.5px;
}

.overlay-note-mini-max .note-body .log-call {
  font-size: 12px;
  color: #4a4a4a;
}

.overlay-note-mini-max .note-body input,
.overlay-note-mini-max .note-body select {
  border: 1px solid #eceaea;
  font-size: 11px;
}

.overlay-note-content {
  width: 50%;
  margin: 0 auto;
  background-color: #f4f4f4;
  color: #777;
  border-radius: 10px;
}

.overlay-add-deals {
  position: relative;
  top: 0;
  width: 40%;
  margin-top: 30px;
  margin: 0 auto;
  background-color: #f4f4f4;
  color: #777;
  float: right;
  padding-bottom: 5px;
}
.cst_btn_Quote,
.cst_btn_Quote_2 {
  padding: 5px 14px;
  cursor: pointer;
}
.cst_btn_Quote {
  background-color: #f36525;
  color: white;
  border: none;
}

.cst_btn_Quote:hover {
  background-color: #e58659;
}

/* New Contact us Section css end */

/* Australia Page Css end */
